.btn-back-vehicle {
  img {
    width: 15px;
    margin-right: 8px;
  }
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  color: #000 !important;
  margin-top: 20px;
  margin-left: 0px;
  padding-left: 0px !important;
  min-width: 0px !important;
  font-size: 16px;
}

.view-vehicle-container {
  .container-carousel-photos {
    position: relative;
    .btn-left {
      left: 5px;
      position: absolute;
      z-index: 999;
      box-shadow: none !important;
      outline: 0px !important;
      top: 17.5px;
// background-color: rgba($gray,.3);
width: 30px;
height: 30px;
border-radius: 15px;
display: flex;
justify-content: center;
align-items: center;
padding: 0px !important;
@media (max-width: 575px) {
  top: 8px;
  background: red;
  padding: 5px;
  border-radius: 50%;
}
i.fa {
  font-size: 25px !important;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}        
}
.btn-right {
  right: 5px;
  position: absolute;
  z-index: 999;
  box-shadow: none !important;
  outline: 0px !important;
  top: 17.5px;
// background-color: rgba($gray,.3);
width: 30px;
height: 30px;
border-radius: 15px;
display: flex;
justify-content: center;
align-items: center;
padding: 0px !important;
@media (max-width: 575px) {
  top: 8px;
  background: red;
  padding: 5px;
  border-radius: 50%;
}
i.fa {
  font-size: 25px !important;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}        
}
}
.image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  i.fa {
    font-size: 80px;
    text-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    position: relative;
    @media (max-width: 600px) {
      font-size: 40px;
    }
  }
  .btn-left {
    position: absolute;
    left: 25px;
    color: #fff;
    box-shadow: none !important;
    outline: 0px !important;
    background-color: rgba(#000,.5);
    width: 40px;
    height: 40px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
    &:not([disabled]):hover {
      background-color: #000;
    }
  }
  .btn-right {
    position: absolute;
    right: 25px;
    color: #fff;
    box-shadow: none !important;
    outline: 0px !important;
    background-color: rgba(#000,.5);
    width: 40px;
    height: 40px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
    &:not([disabled]):hover {
      background-color: #000;
    }
  }
}
.item {
  &-vehicle {
    .card {
      border-radius: 0 !important;
    }

    .owl-theme {
      img {
        width: 100%;
        height: 80px;
        object-fit: cover;
      }
    }

    .stock-number {
      margin: 1em;
      font-size: 1rem;
    }
  }
}

.description {
  &-vehicle {
    span.brand {
      margin-right: 0.3rem;
      border-right: 1px solid $publicBlueGray;
    }
  }
}

.thumbs {
  position: relative;

  @media (max-width: 767px) {
    .owl-dots {
      overflow-x: scroll;
      height: 20px;
    }
  }
}

.arrows-carousel, .owl-nav {
  position: absolute;
  left: 0;
  top: 60px;
  button {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: 0px !important;
    margin-right: 10px;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  @media (max-width: 767px) {
    position: initial;
  }
}
.specs-details {
  border: 1px solid #b7b7b77d;
  .specs-details-title {
    vertical-align: middle;
    h5 {
      font-size: 16px !important;
    }      
  }
}

.details-section {
  border-top: 1px solid #b7b7b77d;
  background-color: $white;
}

.specs-section {
  border-top: 1px solid $publicBlack;
  background-color: $white;
}
}

.thumbs {
  .owl-theme {
    .owl-stage-outer {
      height: 80px;
      @media (max-width: 767px) {
        height: 50px;
      }
    }
  }
}

.owl-theme {
  .owl-stage-outer {
    z-index: 99;
  }

  .owl-rccslider {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 1;

    .row-left {
      position: absolute;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      left: -45px;
      @media (max-width: 767px) {
        left: -25px;
      }

      img {
        width: 40px;
        height: 40px;
      }
    }

    .row-right {
      position: absolute;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      right: -45px;
      @media (max-width: 767px) {
        right: -25px;
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

span.list-style-rcc::before {
  content: "•";
  margin-right: 10px;
  color: #dd3333;
}

.list-style-rcc {
  font-size: 14px;
}