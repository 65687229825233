// Banner

.banner-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  padding: 20px;
  padding-top: 10px;
  min-height: 200px;
  margin-bottom: 0;

  .banner {
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .text-container {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  h3 {
    color: $publicYellow;
    font-size: 28px;
    font-family: "Roboto Bold";
    text-align: center;
    text-transform: uppercase;
    text-shadow: $text-shadow;
  }

  p {
    color: $white;
    white-space: pre-line;
    font-size: 15px;
    font-family: 'Roboto Light';
  }

  img {
    max-width: 90%;
    position: absolute;
    bottom: -70px;
    right: 0;

    @media (max-width: 767px) {
      display: none;
    }
  }
}
