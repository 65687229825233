// Inventory

#inventory {
	padding: 20px;

	.container-scroll-vehicles {
		width: 96%;
		margin: 0 auto;
		margin-bottom: 30px;
	}

	.infinite-scroll-component {			
		overflow-x: hidden !important;
		padding-left: 20px;
		padding-right: 20px;
		@include scrollHome();
	}

	.loading {
		margin-bottom: 20px;
	}

	.no-vehicles {
		text-transform: uppercase;
		text-align: center;
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 18px;
		font-family: 'Roboto Bold';
	}

	.card-vehicle {
		width: 90%;
		margin: 0 auto;
		border-radius: 3px;
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		margin-bottom: 20px;
		background-color: $white;
		img {
			width: 100%;
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
			height: 250px;
			object-fit: cover;
		}
		.title {
			font-family: 'Roboto Bold';
			text-transform: uppercase;
			text-align: center;
			font-size: 16px;
			@include ellipsis();
		}
		.bold {
			font-family: 'Roboto Bold';
			margin-bottom: 5px;
		}
		p {
			font-size: 13px;
		}
		button {
			background-color: $publicBlack;
			text-align: center;
			color: $white !important;
			width: 100%;
		}
		.container-text {
			padding: 20px;
		}
	}

	.container-title {
		display: flex;
		align-items: center;
		width: 100%;
		.title {
			margin-right: 10px;
		}
		.price {
			background-color: $publicYellow;
			padding: 5px;
			text-align: center;
			font-family: 'Roboto Bold';
			font-size: 16px;
			display: inline-block;
			padding-right: 10px;
			padding-left: 10px;
		}
		.container-price {
			flex: 1;
			text-align: right;
		}
	}

	.search-form {
		width: 95%;
		margin: 0 auto;
		margin-top: 10px;
	}
}