// Mixins

@mixin shadow() {
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

@mixin textShadow() {
	text-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

@mixin scroll() {
	/* width */
	&::-webkit-scrollbar {
	  width: 5px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
	  background: #f1f1f1; 
	}
	 
	/* Handle */
	&::-webkit-scrollbar-thumb {
	  background: #888; 
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
	  background: #555; 
	}
}

@mixin scrollHome() {
	/* width */
	&::-webkit-scrollbar {
	  width: 10px;
	  @media (max-width: 767px) {
	  	width: 5px;
	  }
	}

	/* Track */
	&::-webkit-scrollbar-track {
	  background-color: $publicGray;
	  border: 3px solid transparent;
	  border-radius: 9px;
	  background-clip: content-box;
	  border-radius: 30px;
	}
	 
	/* Handle */
	&::-webkit-scrollbar-thumb {
	  background: $publicBlue;
	  border-radius: 30px;
	  width: 10px;
	}
}

@mixin ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin placeholder($color) {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	  color: $color;
	}

	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
	  color: $color;
	}

	&::-ms-input-placeholder { /* Microsoft Edge */
	  color: $color;
	}
}

@mixin placeholderItalic() {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	  font-family: 'Roboto Light Italic';
	}

	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
	  font-family: 'Roboto Light Italic';
	}

	&::-ms-input-placeholder { /* Microsoft Edge */
	  font-family: 'Roboto Light Italic';
	}
}