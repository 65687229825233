.wrapper-content {
  display: flex;
  height: 100vh;

  .container-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    background-color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    border-radius: 200px;
    position: relative;
    top: -70px;
    margin: 0 auto;
    margin-bottom: -50px;
  }
  .card {
    max-width: 500px;
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}

.wrapper-contain {
  width: 90%;
  padding: 4px 0;
  margin: 0 auto;
}
