// About (Admin)

.about-admin {
	td {
		width: 33%;
	}
	.ellipsis {
		display: inline-block;
		max-width: 350px;
		margin: 0px;
		@include ellipsis();
	}
}