// header

header {
  padding: 15px 0;
  background: rgb(221, 51, 51);
  background: -moz-linear-gradient(
    319deg,
    rgba(221, 51, 51, 1) 68%,
    rgba(0, 0, 0, 1) 68%
  );
  background: -webkit-linear-gradient(
    319deg,
    rgba(221, 51, 51, 1) 68%,
    rgba(0, 0, 0, 1) 68%
  );
  background: linear-gradient(
    319deg,
    rgba(221, 51, 51, 1) 68%,
    rgba(0, 0, 0, 1) 68%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dd3333",endColorstr="#000000",GradientType=1);

 @media (max-width: 767px) {
       background: rgb(221, 51, 51);
  background: -moz-linear-gradient(
    319deg,
    rgba(221, 51, 51, 1) 50%,
    rgba(0, 0, 0, 1) 50%
  );
  background: -webkit-linear-gradient(
    319deg,
    rgba(221, 51, 51, 1) 50%,
    rgba(0, 0, 0, 1) 50%
  );
  background: linear-gradient(
    319deg,
    rgba(221, 51, 51, 1) 50%,
    rgba(0, 0, 0, 1) 50%
  );
    }

  @media (max-width: 991px) {
    padding-left: 0px;
  }

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  .logo {
    width: 250px;
    margin-top: 0;
    @media (max-width: 991px) {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 30px;
    }
     @media (max-width: 575px) {
      margin-left: 10px;
     }
  }

  .container-nav {
    //display: flex;
    justify-content: space-between;
    padding-left: 50px;

    @media (max-width: 991px) {
      flex-direction: column;
      padding-left: 0;
    }
  }

  .container-navbar {
    display: flex;
    .container-logo {
      margin-right: 20px;
      margin-left: 20px;

      a img:hover {
        opacity: 0.8;
        -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .navbar-toggler i.fa {
    //color: $publicBlue !important;
    color: $publicBlueGray !important;
  }

  .navbar {
    background-color: transparent !important;
    .nav-link {
      color: $white !important;
      margin-top: 0;
      font-family: "Roboto Bold";
      padding-right: 1.5rem;
      padding-left: 1.5rem;

      &.active {
        color: lighten($black, 10) !important;
      }

      &:hover {
        opacity: 0.8;
        -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      }

      @media (max-width: 1200px) {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    @media (max-width: 991px) {
      width: 100%;
      .navbar-toggler {
        position: relative;
        right: 0;
        background: $white;
      }
    }
  }

  .container-lang {
    display: none !important;
    float: right;
    width: 100px;
    border-right: 2px solid $publicBlue;
    border-left: 2px solid $publicBlue;
    display: inline-block;
    width: auto;
    text-align: center;
    .item-lang {
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
      display: block;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:nth-child(1) {
        border-bottom: 2px solid $publicBlue;
      }
      &.active {
        background-color: $publicBlue;
        a {
          color: $white !important;
        }
      }
    }
    a {
      text-decoration: none !important;
      color: $publicBlue !important;
      text-align: center;
      font-family: "Roboto Bold";
    }
  }

  .col-gray {
    background-color: $publicGray;
    padding-right: 0;
    @media (max-width: 600px) {
      padding-right: 50px;
    }
  }

  .phone {
    background-color: $white;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-block;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
    margin-top: 20px;
    margin-right: 40px;
    margin-left: 40px;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
    p {
      display: inline-block;
      margin: 0px;
      margin-left: 10px;
    }
    i.fa {
      color: $publicBlue;
    }
  }
}

header.sticky-fixed {
  position: fixed;
  width: 100%;
  z-index: 9999;

  @media (max-width: 767px) {
    position: initial;
    background: linear-gradient(319deg, #d33 50%, #000 0);
  }
}

@media (max-width: 767px) {
  .public-container {
    margin-top: 0 !important;
  }
}
