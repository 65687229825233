.vehicle-container {
  .vehicle-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  width: 90%;
  @media (max-width: 767px) {
    max-width: 300px;
  }
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: $white;
  .row-stock {
    p {
      // margin-top: 10px;
      text-align: center;
      font-size: 13px;
      margin-bottom: 0;
    }
  }
  .container-image {
    cursor: pointer;
    height: 17vw;
    @media (max-width: 991px) {
      height: 190px;
    }
  }
  img {
    width: 100%;
    height: 17vw;
    object-fit: cover;
    //object-fit: contain;
    @media (max-width: 991px) {
      height: 200px;
    }
  }
  .lazy-load-image-background {
    width: 100%;
  }
  a {
    text-decoration: none !important;
    color: $publicBlack !important;
  }
  .flex {
    display: flex;
    width: 100%;
    margin-top: 20px;
     @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
  .callprice {
    padding: 10px;
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
    background-color: $publicYellow;
    text-transform: uppercase;
    flex: 1;
    font-size: 11px;
    font-family: "Roboto Bold";
    text-align: center;
    margin: 0px !important;
    width: 100%;
    overflow: hidden;
  }
  .price {
    background-color: $publicYellow;
    padding: 5px 5px;
    color: $white;
    text-align: center;
    font-family: "Roboto Bold";
    font-size: 11px;
    display: inline-block;
    flex: 1;
    margin: 0px !important;
    width: 100%;
    overflow: hidden;
  }
  .name {
    text-transform: uppercase;
    // text-align: center;
    font-size: 18px;
    p {
      @include ellipsis();
      width: 90%;
      margin-top: 0px;
      margin-bottom: 0px;
      font-family: "Roboto Bold";
      font-size: 12px;
    }
    flex: 1;
    margin: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    padding-top: 10px;
  }
  .row-stock {
    @media (max-width: 767px) {
      margin-top: 10px;
    }
    width: 100%;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 5px;
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      padding-top: 5px !important;
      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin-right: 5px;
      }
    }
    i.fa {
      color: $publicYellow;
      font-size: 20px;
      margin-right: 5px;
    }
  }
  button.details {
    background-color: $black !important;
    color: $white !important;
    text-transform: uppercase;
    border: 1px solid $black;
    width: 90%;
    margin-bottom: 15px;
    border-radius: 5px !important;
    font-size: 14px;
  }
  .mileage {
    white-space: pre;
  }
}
