// Contact

#contact {
  $shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  iframe {
    width: 100%;
    min-height: 300px;
  }
  h3 {
    font-family: "Roboto Bold";
    font-size: 25px;
  }
  form {
    background-color: $gray;
    border-radius: 5px;
    padding: 20px;
    box-shadow: $shadow;
    margin-top: 10px;
    margin-bottom: 20px;
    input,
    textarea {
      background-color: $white !important;
      box-shadow: none !important;
      border-radius: 0px !important;
      border: 1px solid $publicBlueGray !important;
      font-size: 14px;
      padding: 5px !important;
      @include placeholder($publicBlueGray);
    }
    button {
      background-color: $white !important;
      border: 1px solid $publicBlueGray !important;
      border-radius: 5px !important;
      text-transform: uppercase;
      font-family: "Roboto Bold";
      font-size: 14px !important;
      width: 150px;
      color: $publicBlueGray !important;
      box-shadow: none !important;
    }
  }
  .capitalize {
    text-transform: capitalize;
  }
  .table-hours {
    box-shadow: $shadow;
    border: 1px solid $publicYellow;
    border-radius: 5px;
    margin-bottom: 25px;
    .table-hours-header {
      background-color: $black;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      p {
        color: $white;
        font-size: 18px;
        font-family: "Roboto Bold";
        padding: 10px;
        padding-bottom: 5px;
        padding-top: 5px;
      }
    }
    .table-hours-body {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      padding: 5px;
      width: 100%;
      margin: 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: -15px;
      &.table-information {
        padding: 10px;
        h4 {
          font-family: "Roboto Bold";
          font-size: 16px;
          margin-top: 5px;
          margin-left: 10px;
          margin-bottom: 20px;
        }
        p {
          margin-top: 10px;
          margin-left: 10px;
          img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
            object-fit: contain;
          }
        }
      }
      .item-table-hours {
        display: flex;
        width: 100%;
        p {
          &:nth-child(1) {
            font-family: "Roboto Bold";
          }
          flex: 0.5;
          font-size: 14px;
          margin-bottom: 5px;
          margin-top: 5px;
          padding-right: 30px;
          padding-left: 30px;
          white-space: pre;
          @media (max-width: 600px) {
            padding-right: 5px;
            padding-left: 5px;
          }
        }
        border-bottom: 1px solid $publicYellow;
        &:last-child {
          border: 0px;
        }
      }
    }
  }
  .sociales {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      @media (max-width: 600px) {
        width: 35px;
        height: 35px;
      }
    }
    a {
      display: inline-block;
    }
  }
}
