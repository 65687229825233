// Footer

footer {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	// background-color: $publicBlue;	
	padding: 15px 50px 1px;
	// padding-top: 30px;
	// padding-bottom: 10px;
	@media (max-width: 767px) {
		text-align: center;
	}
	.row {
		margin-bottom: -2vh;
	}
	.btn-model-footer{
		border: solid 1px red;
		text-decoration: none;
		p{
			margin: 0;
		}
	}
	.logo {
		width: 120px;
		margin-top: 15px;		
	}
	p {
		color: $white;
		margin: 0px;
		font-size: 13px;
		margin-bottom: 15px;
		img {
			width: 25px;
			margin-right: 10px;
			position: absolute;
			left: -20px;
		}
		@media (max-width: 767px) {
			text-align: center;
			margin: 0 auto;
			margin-top: 30px;
			img {
				position: relative; 
				left: 0px;
			}
		}
	}
	.h4-email {
		@media (min-width: 768px) {
			margin-top: 10px;
			margin-bottom: 5px;
		}
	}
	.container-socials {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		.row-socials {
			width: 100%;
			margin-top: 10px;
			i.fa {
				font-size: 30px;
				color: $publicYellow;
			}
			@media (max-width: 767px) {
				margin-top: 30px;
			}
		}
	}
	.whatsapp-container {
		.whatsapp-button {
			display: inline-flex;
		}
		text-align: right;
		@media (max-width: 767px) {
			text-align: center;
		}
		margin-top: 30px;
	}
	.flex-brands {
		display: flex;
		@media (max-width: 767px) {
			flex-direction: column;
		}
		.flex-33 {
			flex: .33;
		}
		a {
			color: $white !important;
			text-decoration: none !important;
			text-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
			p {
				margin: 0px !important;
				font-size: 13px;
				margin-bottom: 2px !important;		
			}
		}
		h3 {
			font-size: 14px;
			text-transform: uppercase;
			color: $publicYellow;
			font-family: 'Roboto Bold';
			margin-bottom: 10px;
			@media (max-width: 767px) {
				text-align: center;
				margin-top: 25px;
			}
		}
	}
}

.copyright-container {
	.copyright {
		margin-top: 30px !important;
		margin-bottom: 10px !important;
		font-size: 13px;
		@media (max-width: 767px) {
			margin-bottom: 80px !important;
		}
		a,a:hover {
			color: $white;
			text-decoration: none;
		}
	}
}