// Home

#home {
// Banner
.banner {
  .title {
    color: $publicYellow;
    text-align: center;
    font-family: "Roboto Bold";
    margin-bottom: 20px;
    margin-top: 40px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    @media (max-width: 991px) {
      font-size: 25px;
    }
    @media (max-width: 700px) {
      margin-top: 20px;
      font-size: 20px;
    }
  }
  .subtitle {
    color: $white;
    text-align: center;
    margin-bottom: 40px;
    font-family: 'Roboto Bold Italic';
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    @media (max-width: 991px) {
      font-size: 25px;
    }
    @media (max-width: 700px) {
      font-size: 18px;
    }
  }
  .container-banner { 
    max-width: 90%;
    margin: 0 auto;
    @media (max-width: 600px) {
      max-width: 100%;
      margin-top: 30%;
    }
  }
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 340px 0;
  @media (max-width: 600px) {
    padding: 80px 0;
  }
  margin-bottom: 40px;
  .input-group,

  input {
    background-color: $white !important;
    border-radius: 30px;
    @include placeholderItalic();
    &:focus {
      background-color: #ffdbdc !important;
      transform: scale(1.14);
      transition: 0.6s;
      font-size: 18px;
       @media (max-width: 600px) {
      transform: scale(1.03);
    }
    }
  }
  .brands {
    margin-bottom: 20px;
  }

  .brands-container {
    max-width: 80%;
    margin: auto;
  }

  .input-group {
    max-width: 62%;
    margin: -150px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
      max-width: 80%;
    }
    i.fa {
      color: $publicBlue;
      font-size: 20px;
      margin-right: 20px;
      margin-left: 5px;
    }
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin-right: 20px;
      margin-left: 5px;
      z-index: 9999;
    position: relative;
    }
  }
  .whatsapp-container {
    text-align: right;
    margin-top: 15px;
    .whatsapp-button {
      display: inline-flex;
    }
    @media (max-width: 767px) {
      position: relative;
      right: 0px;
      text-align: center;
      .whatsapp-button {
        display: inline-flex;
      }
      .brands-container {
        max-width: 100%;
      }
    }
  }
  a {
    color: $white !important;
    p {
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
      0 1px 2px rgba(0, 0, 0, 0.24);
      font-size: 14px;
    }
  }
}

// Nuevo Inventario
.carousel {
  h3 {
    text-align: center;
    margin-bottom: 30px;
    font-family: "Roboto Bold";
    font-size: 22px;
    text-transform: uppercase;
  }
  .owl-carousel {
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    z-index: 999;
  }
  .vehicle-container {
    width: 80%;
    @media (max-width: 600px) {
      width: 90%;
    }
  }
}

.section-new-inventory {
// width: 85%;
// margin: 0 auto;
}

// Instagram
.instagram {
  .owl-carousel {
    .item {
      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        margin: 0 auto;
      }
    }
  }
}

// testimonio 

.testimonio {
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  padding: 50px 0;
   @media (max-width: 767px) {
      margin-top: -50px;
    }
  .item {
    background-color: #f3f3f3;
    text-align: center;
    padding: 20px 35px;
    border: solid 1px #f3f3f3;
    margin: 0 8px;
    h2 {
      font-size: 18px;
    }
  }
  .owl-rccslider img {
    background-color: #dd3333;
    border-radius: 50%;
    padding: 8px;
    border: solid 0.2rem #f7f7f7;
  }
  .owl-theme .owl-dots .owl-dot span {
    border-radius: 50%;
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background-color: $publicBlueGray;
  }
  .owl-theme .owl-rccslider .row-left {
    left: 100px;
    bottom: -10px;
  }
  .owl-theme .owl-rccslider .row-right {
    right: 100px;
    bottom: -10px;
  }
  .owl-theme .owl-dots {
    margin-top:25px;
  }
}

// About
.about {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
  .flex {
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 40% 100%;
    margin: 0px;
    display: flex;

    @media (max-width: 1122px) {
      flex-direction: column;
      background-image: none !important;
    }

    @media (min-width: 1919px) {
      background-size: contain;
    }

    .container-about {
      flex: 0.5;
      padding: 80px 20px;
      @media (min-width: 1441px) {
        padding-left: 120px;
      }
      @media (max-width: 768px) {
        padding: 16px 15px;
      }
// @media (max-width: 1440px) {
//   padding-left: 60px;
// }
// @media (max-width: 1122px) {
//   padding-right: 20px;
//   padding-left: 20px;
// }
.container-text {
  max-width: 550px;
// border-left: 10px solid $publicYellow;
//padding-left: 20px;
margin-top: 20px;
margin-bottom: 20px;
@media (max-width: 1122px) {
  max-width: 100%;
  p {
    text-align: justify;
  }
}
h3 {
  font-family: "Roboto Bold";
  text-transform: uppercase;
  font-size: 20px;
  @media (min-width: 1441px) {
    font-size: 22px;
  }
  @media (max-width: 1440px) {
    font-size: 20px;
  }
  margin-bottom: 20px;
  color: $publicBlue;
}
p {
  font-size: 14px;
  @media (min-width: 1441px) {
    font-size: 18px;
  }
  @media (max-width: 1440px) {
    font-size: 14px;
  }
  white-space: pre-line;
  margin-bottom: 0px;
}
}
}
}
}

.search-form {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.inventory {
  margin-bottom: 30px;
  h3 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: "Roboto Bold";
    font-size: 22px;
    text-transform: uppercase;
  }
  .container-inventario {
// div {
//   padding-right: 0;
//   padding-left: 0;
// }

@media (max-width: 600px) {
  width: 100%;
}
}
.infinite-scroll-component {
  overflow-x: hidden !important;
  padding-left: 20px;
  padding-right: 20px;
  @include scrollHome();
}
}
}

@media (max-width: 767px) {
  .brands-container {
    max-width: 100%;
  }
}

.iframe {
  padding: 25px 0;

  iframe {
    border: none;
    margin: auto;
    display: Block;
  }
  @media (max-width: 767px){
    iframe {
      height: 200px;
      width: 100%;
    }
  }
}


