// Vehicles

.vehicles-admin{
    .table-component {
        background-color: $white;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;
        thead {
            border-bottom: 1px solid $gray;
            vertical-align: middle;
            padding: 0.2rem 0.4rem;
            th {
                border: 0px !important;
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid $gray;
            }
            td {
                padding: 0.2rem 0.4rem;
                vertical-align: middle;
                button {
                    margin-right: 5px;
                }
            }
        }
    }
    #modal-data-photos-vehicle{
        .vehicle-photos-container {
            padding: 0.75rem;
            .row {
                padding: 0.75rem;
            }
        }
    }
}