$url: "../../fonts/";

@font-face {
  font-family: "Roboto Black Italic";
  src: url($url + "Roboto-BlackItalic.eot");
  src: url($url + "Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url($url + "Roboto-BlackItalic.woff2") format("woff2"),
    url($url + "Roboto-BlackItalic.woff") format("woff"),
    url($url + "Roboto-BlackItalic.ttf") format("truetype"),
    url($url + "Roboto-BlackItalic.svg#Roboto-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Medium Italic";
  src: url($url + "Roboto-MediumItalic.eot");
  src: url($url + "Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url($url + "Roboto-MediumItalic.woff2") format("woff2"),
    url($url + "Roboto-MediumItalic.woff") format("woff"),
    url($url + "Roboto-MediumItalic.ttf") format("truetype"),
    url($url + "Roboto-MediumItalic.svg#Roboto-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Thin";
  src: url($url + "Roboto-Thin.eot");
  src: url($url + "Roboto-Thin.eot?#iefix") format("embedded-opentype"),
    url($url + "Roboto-Thin.woff2") format("woff2"),
    url($url + "Roboto-Thin.woff") format("woff"),
    url($url + "Roboto-Thin.ttf") format("truetype"),
    url($url + "Roboto-Thin.svg#Roboto-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Italic";
  src: url($url + "Roboto-Italic.eot");
  src: url($url + "Roboto-Italic.eot?#iefix") format("embedded-opentype"),
    url($url + "Roboto-Italic.woff2") format("woff2"),
    url($url + "Roboto-Italic.woff") format("woff"),
    url($url + "Roboto-Italic.ttf") format("truetype"),
    url($url + "Roboto-Italic.svg#Roboto-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url($url + "RobotoCondensed-Regular.eot");
  src: url($url + "RobotoCondensed-Regular.eot?#iefix")
      format("embedded-opentype"),
    url($url + "RobotoCondensed-Regular.woff2") format("woff2"),
    url($url + "RobotoCondensed-Regular.woff") format("woff"),
    url($url + "RobotoCondensed-Regular.ttf") format("truetype"),
    url($url + "RobotoCondensed-Regular.svg#RobotoCondensed-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Bold Italic";
  src: url($url + "Roboto-BoldItalic.eot");
  src: url($url + "Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url($url + "Roboto-BoldItalic.woff2") format("woff2"),
    url($url + "Roboto-BoldItalic.woff") format("woff"),
    url($url + "Roboto-BoldItalic.ttf") format("truetype"),
    url($url + "Roboto-BoldItalic.svg#Roboto-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Condensed Italic";
  src: url($url + "RobotoCondensed-Italic.eot");
  src: url($url + "RobotoCondensed-Italic.eot?#iefix")
      format("embedded-opentype"),
    url($url + "RobotoCondensed-Italic.woff2") format("woff2"),
    url($url + "RobotoCondensed-Italic.woff") format("woff"),
    url($url + "RobotoCondensed-Italic.ttf") format("truetype"),
    url($url + "RobotoCondensed-Italic.svg#RobotoCondensed-Italic")
      format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Condensed Bold";
  src: url($url + "RobotoCondensed-Bold.eot");
  src: url($url + "RobotoCondensed-Bold.eot?#iefix") format("embedded-opentype"),
    url($url + "RobotoCondensed-Bold.woff2") format("woff2"),
    url($url + "RobotoCondensed-Bold.woff") format("woff"),
    url($url + "RobotoCondensed-Bold.ttf") format("truetype"),
    url($url + "RobotoCondensed-Bold.svg#RobotoCondensed-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Light";
  src: url($url + "Roboto-Light.eot");
  src: url($url + "Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url($url + "Roboto-Light.woff2") format("woff2"),
    url($url + "Roboto-Light.woff") format("woff"),
    url($url + "Roboto-Light.ttf") format("truetype"),
    url($url + "Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Condensed Light";
  src: url($url + "RobotoCondensed-Light.eot");
  src: url($url + "RobotoCondensed-Light.eot?#iefix")
      format("embedded-opentype"),
    url($url + "RobotoCondensed-Light.woff2") format("woff2"),
    url($url + "RobotoCondensed-Light.woff") format("woff"),
    url($url + "RobotoCondensed-Light.ttf") format("truetype"),
    url($url + "RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url($url + "Roboto-Regular.eot");
  src: url($url + "Roboto-Regular.eot?#iefix") format("embedded-opentype"),
    url($url + "Roboto-Regular.woff2") format("woff2"),
    url($url + "Roboto-Regular.woff") format("woff"),
    url($url + "Roboto-Regular.ttf") format("truetype"),
    url($url + "Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Medium";
  src: url($url + "Roboto-Medium.eot");
  src: url($url + "Roboto-Medium.eot?#iefix") format("embedded-opentype"),
    url($url + "Roboto-Medium.woff2") format("woff2"),
    url($url + "Roboto-Medium.woff") format("woff"),
    url($url + "Roboto-Medium.ttf") format("truetype"),
    url($url + "Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Thin Italic";
  src: url($url + "Roboto-ThinItalic.eot");
  src: url($url + "Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url($url + "Roboto-ThinItalic.woff2") format("woff2"),
    url($url + "Roboto-ThinItalic.woff") format("woff"),
    url($url + "Roboto-ThinItalic.ttf") format("truetype"),
    url($url + "Roboto-ThinItalic.svg#Roboto-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Bold";
  src: url($url + "Roboto-Bold.eot");
  src: url($url + "Roboto-Bold.eot?#iefix") format("embedded-opentype"),
    url($url + "Roboto-Bold.woff2") format("woff2"),
    url($url + "Roboto-Bold.woff") format("woff"),
    url($url + "Roboto-Bold.ttf") format("truetype"),
    url($url + "Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Condensed Bold Italic";
  src: url($url + "RobotoCondensed-BoldItalic.eot");
  src: url($url + "RobotoCondensed-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url($url + "RobotoCondensed-BoldItalic.woff2") format("woff2"),
    url($url + "RobotoCondensed-BoldItalic.woff") format("woff"),
    url($url + "RobotoCondensed-BoldItalic.ttf") format("truetype"),
    url($url + "RobotoCondensed-BoldItalic.svg#RobotoCondensed-BoldItalic")
      format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Light Italic";
  src: url($url + "Roboto-LightItalic.eot");
  src: url($url + "Roboto-LightItalic.eot?#iefix") format("embedded-opentype"),
    url($url + "Roboto-LightItalic.woff2") format("woff2"),
    url($url + "Roboto-LightItalic.woff") format("woff"),
    url($url + "Roboto-LightItalic.ttf") format("truetype"),
    url($url + "Roboto-LightItalic.svg#Roboto-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Black";
  src: url($url + "Roboto-Black.eot");
  src: url($url + "Roboto-Black.eot?#iefix") format("embedded-opentype"),
    url($url + "Roboto-Black.woff2") format("woff2"),
    url($url + "Roboto-Black.woff") format("woff"),
    url($url + "Roboto-Black.ttf") format("truetype"),
    url($url + "Roboto-Black.svg#Roboto-Black") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Condensed Light Italic";
  src: url($url + "RobotoCondensed-LightItalic.eot");
  src: url($url + "RobotoCondensed-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url($url + "RobotoCondensed-LightItalic.woff2") format("woff2"),
    url($url + "RobotoCondensed-LightItalic.woff") format("woff"),
    url($url + "RobotoCondensed-LightItalic.ttf") format("truetype"),
    url($url + "RobotoCondensed-LightItalic.svg#RobotoCondensed-LightItalic")
      format("svg");
  font-weight: 300;
  font-style: italic;
}

.font {
  font-family: $font-family !important;
}

.font-bold {
  font-family: $font-family-bold !important;
}
