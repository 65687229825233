// Menu

:root {
  --landscape-width: 22vw; // % OR vw for responsive window resizing! 22
  --portrait-width: 100%;
  --header-height: 35px;
  --secs: 0.6s;
  --bg-left: springgreen;
  --bg-right: cyan;
  --bg-main: lemonchiffon;
}
#layout {
  display: flex; // Flexbox definition
  overflow: hidden; // Disable scrolling on layout

  // Style in common for sidebars and main area:
  .header {
    position: relative;
    height: var(--header-height);
    text-align: center; // Horizontal center
    .title { position: absolute; left: 0; right: 0; }
  }
  .content {
    overflow: auto;
    box-sizing: border-box;
    height: calc(100vh - var(--header-height));
    padding: 1rem;
  }

  .container-router {
    position: absolute;
    width: 100%;
    transition: var(--secs);
    &.open {
      @media (min-width: 768px) {
        width: calc(100% - 305px);
        margin-left: 300px;
      }
    }
  }
}



// Style in common for both sidebars:
#left, #right { transition: width var(--secs); width: 0;
  @media (orientation: landscape) {
    &.open { width: calc(1% * var(--landscape-width)); } // POR DEFECTO LLEVA 1%
  } // <-- Landscape ONLY sidebars
  .icon { position: fixed; z-index: 10;
    width: var(--header-height); height: var(--header-height);
    line-height: var(--header-height); font-size: var(--header-height);
    text-align: center; user-select: none; cursor: pointer;
    color: white;
    //background-color: #222d32;
    background-color: rgba(34,45,50, 0.7);
    border-radius: 50%;
  }
  .sidebar { transition: transform var(--secs); // <-- Portrait AND landscape!
    @media (orientation: portrait) { width: var(--portrait-width); }
    @media (orientation: landscape) { width: calc(1vw * var(--landscape-width)); }
    .header { width: calc(100% - var(--header-height)); }
  }
}

// Left sidebar specific style:
#left { z-index: 5;
  .sidebar { background: var(); 
    width: var(--portrait-width);
    &.closed { transform: translateX(-300px); } // <-- Left portrait AND landscape!
    .header { left: var(--header-height); }
  }
}