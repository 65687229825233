// Home

#about-us {
  // Banner
  .banner {
    .title {
      color: $publicYellow;
      text-align: center;
      font-family: "Roboto Bold";
      margin-bottom: 20px;
      margin-top: 40px;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      @media (max-width: 991px) {
        font-size: 25px;
      }
      @media (max-width: 700px) {
        margin-top: 20px;
        font-size: 20px;
      }
    }
    .subtitle {
      color: $white;
      text-align: center;
      margin-bottom: 40px;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      @media (max-width: 991px) {
        font-size: 25px;
      }
      @media (max-width: 700px) {
        font-size: 18px;
      }
    }
    .container-banner {
      max-width: 90%;
      margin: 0 auto;
      @media (max-width: 600px) {
        max-width: 100%;
      }
    }
    background-color: $publicGray;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
    margin-bottom: 40px;
    .input-group,
    input {
      background-color: $white !important;
      border-radius: 30px;
    }
    .brands {
      margin-bottom: 20px;
    }

    .brands-container {
      max-width: 80%;
      margin: auto;
    }

    .input-group {
      max-width: 80%;
      margin: 0 auto;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 600px) {
        max-width: 100%;
      }
      i.fa {
        color: $publicBlue;
        font-size: 20px;
        margin-right: 20px;
        margin-left: 5px;
      }
      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 20px;
        margin-left: 5px;
      }
    }
    .whatsapp-container {
      text-align: right;
      margin-top: 15px;
      .whatsapp-button {
        display: inline-flex;
      }
      @media (max-width: 767px) {
        position: relative;
        right: 0px;
        text-align: center;
        .whatsapp-button {
          display: inline-flex;
        }
        .brands-container {
          max-width: 100%;
        }
      }
    }
    a {
      color: $black-dark !important;
      p {
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
          0 1px 2px rgba(0, 0, 0, 0.24);
        font-size: 14px;
      }
    }
  }

  // Nuevo Inventario
  .carousel {
    width: 90%;
    margin: 0 auto;
    h3 {
      text-align: center;
      margin-bottom: 30px;
      font-family: "Roboto Bold";
      font-size: 22px;
      text-transform: uppercase;
    }
    .owl-carousel {
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }

  // About
  .about {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    @media (max-width: 991px) {
      flex-direction: column;
      //background-image: none !important;
      background-size: cover;
    }
    @media (min-width: 991px) {
    .offset-xl-6 {
    margin-left: 50%;
} }
    .flex {
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: contain;
      margin: 0px;
      display: flex;
      @media (max-width: 991px) {
        flex-direction: column;
        //background-image: none !important;
        background-size: cover;
      }
      .container-about {
        flex: 0.7;
        padding: 60px;
        padding-right: 50px;
        padding-left: 60px;
        @media (min-width: 1441px) {
          padding-left: 120px;
        }
        @media (max-width: 1440px) {
          padding-left: 60px;
        }
        @media (max-width: 1122px) {
          padding-right: 20px;
          padding-left: 20px;
        }
        .container-text {
          max-width: 380px;
          border-left: 10px solid $publicYellow;
          padding-left: 20px;
          margin-top: 20px;
          margin-bottom: 20px;
          @media (max-width: 991px) {
            max-width: 100%;
            background: #ffffffe0;
            padding: 14px;
            p {
              text-align: justify;
            }
          }
          h3 {
            font-family: "Roboto Bold";
            text-transform: uppercase;
            font-size: 20px;
            @media (min-width: 1441px) {
              font-size: 22px;
            }
            @media (max-width: 1440px) {
              font-size: 20px;
            }
            margin-bottom: 20px;
            color: $publicBlue;
          }
          p {
            font-size: 14px;
            @media (min-width: 1441px) {
              font-size: 18px;
            }
            @media (max-width: 1440px) {
              font-size: 14px;
            }
            white-space: pre-line;
            margin-bottom: 0px;
          }
        }
        .container-image-text {
          max-width: 350px;
          margin-top: 20px;
          margin-bottom: 20px;
          img {
            width: 6rem;
          }
          p {
            font-size: 16px;
            white-space: pre-line;
            margin-top: 18px;
            text-align: center;
            line-break: anywhere;
            a,
            a:hover {
              color: $black-dark;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .search-form {
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .inventory {
    margin-bottom: 30px;
    h3 {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
      font-family: "Roboto Bold";
      font-size: 22px;
      text-transform: uppercase;
    }
    .container-inventario {
      width: 80%;
      @media (max-width: 600px) {
        width: 90%;
      }
      margin: 0 auto;
    }
    .infinite-scroll-component {
      overflow-x: hidden !important;
      padding-left: 20px;
      padding-right: 20px;
      @include scrollHome();
    }
  }
}

@media (max-width: 767px) {
  .brands-container {
    max-width: 100%;
  }
}
