// Gallery

#gallery {
  margin-top: 60px;
  margin-bottom: 20px;
  .infinite-scroll-component {
    overflow: hidden !important;
  }
  .loading {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  h4,
  h5 {
    text-transform: uppercase;
    margin-bottom: 20px;
    width: 100%;
    margin: 0 auto;
    font-family: "Roboto Bold";
  }
  .gallery-item {
    border-bottom: 1px solid $publicGray;
    margin: 0 auto;
    padding-bottom: 20px;
    padding-top: 20px;
    .card {
      padding: 5px;
    }
    img, .view-more {
      cursor: pointer;
    }    
    @media (max-width: 991px) {
      max-width: 300px;
    }
    &:last-child {
      border: 0px !important;
    }
    .view-more {
      background-color: $publicGalleryTwo;
      width: 100%;
      cursor: pointer;
      height: 17vw;
      @media (max-width: 991px) {
        height: 250px;
      }
      p {
        border: 1px solid $white;
        width: 90%;
        height: 85%;
        margin: 0 auto;
        position: relative;
        top: 7.5%;
        text-align: center;
        color: $white;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .img-gallery-item {
    width: 100%;
    height: 17vw;
    object-fit: cover;
    @media (max-width: 991px) {
      height: 250px;
      margin-bottom: 20px;
    }
  }
  .btn-info-gallery {
    border-radius: 30px;
    font-size: 14px;
    min-width: 200px;
    box-shadow: none !important;
    outline: 0px !important;
    margin-right: 10px;
    font-family: $font-family-bold;
    @media (max-width: 767px) {
      float: none !important;
      margin: auto;
      display: block;
      margin-top: 15px;
     }
  }
}
#galle-ry

 .gallery-item { 
      .card-body { 
        .d-flex {
          display: block !important;
          margin: auto;
        }
      }
    }
 .banner-container p {
  color:$black;
  @media (max-width: 767px) {
    color: #000000;
    background: rgba(255, 255, 255, 0.65);
    font-weight: 900;
    border-radius: 12px;
    text-align: center;
    width: auto;
    margin: auto;
    display: block;
    padding: 5px 12px;
    font-size: 15px;
    line-height: 1.3rem;
    }
}