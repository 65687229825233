.popup-overlay {
  z-index: 9999 !important;
  .popup-content {
    padding: 0 !important;
    border: none !important;
    background: transparent !important;
    .popUp {
      width: 100%;
      height: 100%;
      padding-bottom: 30px;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;

      @media (min-width: 1440px) {
        height: 600px;
      }

      .container-text {
        max-width: 50%;
        overflow: auto;

        @media (max-width: 768px) {
          max-width: 100%;
        }

        @media (max-width: 1440px) {
          flex-direction: column;
        }

        h3 {
          font-family: "Roboto Bold";
          text-transform: capitalize;
          font-size: 2vw;
          @media (min-width: 1441px) {
            font-size: 2vw;
          }
          @media (max-width: 1440px) {
            font-size: 2rem;
          }
          margin-top: 2vw;
          margin-bottom: 1vw;
          color: $publicYellow;
        }
        p {
          font-weight: bold;
          color: $white;
          text-align: justify;
          word-wrap: break-word;
          @media (min-width: 1440px) {
            font-size: 16px;
          }
          @media (max-width: 1439px) {
            font-size: 12px;
          }
          white-space: pre-line;
          margin-bottom: 0px;
        }
      }
      .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #ffffff;
        border-radius: 18px;
        border: 1px solid #cfcece;
      }
    }
  }
}

.popUpClose {
  font-weight: bold;
  width: 25px;
  height: 25px;
  text-align: center;
  color: $publicYellow;
  font-size: 20px;
  position: absolute;
  right: 15px;
  cursor: pointer;
  z-index: 99;
}
