// Collections (Admin)

.collections-admin {
	td {
		width: 50%;
	}
	.ellipsis {
		display: inline-block;
		max-width: 350px;
		margin: 0px;
		@include ellipsis();
	}
}

.edit-collection {
	h3 {
		font-size: 18px;
		font-family: 'Roboto Light';
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.container-paper-clip {
		button {
			i.fa {
				display: inline-block;
				margin-right: 10px;
			}
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}
	.no-images {
		font-size: 12px;
		font-family: 'Roboto Light';
	}
	.item {
		text-align: center;
		img {
			width: 90%;
			margin: 0 auto;
			height: 220px;
			object-fit: cover;
		}
		button {
			margin-top: 20px;
			font-size: 12px;
			background-color: $danger !important;
			color: $white !important;
			i.fa {
				margin-right: 5px;
			}
		}
	}
}