// Base

* {
	font-family: 'Roboto';
}

#public {
	overflow-x: hidden;
	background-color: $white;
	.public-container {
		min-height: calc(100vh - 268px) !important;	
	}	
}

.layout-admin {
	background-color: $gray;
}

.container-menu {
	display: flex;
	flex-direction: row;
}

.navbar-toggler {
	border: 0px !important;
	outline: 0px !important;
}

.navbar.navbar-admin {	
	@media (min-width: 768px) {
		display: none;	
	}
	.navbar-collapse {
		a {
			color: $white !important;
		}
	}
}
.modal.high-modal {
	.modal-dialog {
		max-width: 80% !important;
		@media (max-width: 600px) {
			max-width: 100% !important;
		}
	}
}
.nav-link {
	.badge {
		background-color: $blue;
		margin-left: 10px;
		border-radius: 30px;
	}
}

.menu {
	@media (max-width: 767px) {
		display: none;
	}
	min-height: 100vh;
	background-color: $black;
	width: 300px;
	position: relative;
	.container-user {
		display: flex;
		align-items: center;
		margin-top: 40px;
		margin-bottom: 20px;
		margin-left: 15px;
		width: 80%;
		img {
			width: 50px;
			height: 50px;
			border-radius: 100px;
			margin-right: 20px;
			object-fit: cover;
		}
		h2 {
			color: $white;
			font-family: 'Roboto Bold';
			font-size: 16px;
			margin-top: 10px;
		}
	}
	.btn-logout {
		color: $white !important;
		position: absolute;
		right: 10px;
		top: 10px;
		font-size: 20px;
		box-shadow: none !important;
		border: 0px !important;
		outline: 0px !important;
	}
	ul {
		padding: 0px !important;
		list-style: none;
		li {
			&.active {
				background-color: $active;
				border-left: 3px solid $blue !important;
			}			
			a {
				position: relative;
				display: flex;
				align-items: center;
				color: $white !important;
				text-decoration: none !important;
				padding: 10px !important;
				.badge {
					background-color: $blue;
					margin-left: 10px;
					border-radius: 30px;
				}
				i {
					position: absolute;
					right: 10px;
				}
			}
		}
	}
}

.container-router {
	padding: 20px;
	width: 100%;
	overflow-y: scroll;
	height: 100vh;
	@media (max-width: 767px) {
		padding-top: 70px;
	}
}

.ver-todo {
	color: $blue;
	display: inline-block;
	cursor: pointer;
	font-family: 'Roboto Bold';
	margin-top: 0px !important;
}

.btn-profile {
	margin-right: 40px;
}

.fa.danger {
	color: $danger;
}

.fa.success {
	color: $green;
}

.react-viewer {
	position: relative;
	z-index: 9999;
}

.input-file-paperclip {
	display: none;
}

.modal-gallery {
  .modal-body {
    background-color: transparent !important;
    .shadow-sm {
    	background-color: #fff;
    }
  }
  button.btn {
  	position: relative;
  	right: -20px;
    i.fa {
      color: #fff;
      font-size: 20px;
    }
  }
}

.react-viewer-canvas {
	background-color: #000;
}

.react-viewer-footer {
	overflow: initial !important;
	.react-viewer-toolbar {
		overflow: initial !important;
		.react-viewer-icon-prev:before {
			background-image: url(../../icons/left-white.png);
			width: 20px;
			height: 20px;
			background-size: contain;
			display: inline-block;
			content: '';
		}
		.react-viewer-icon-next:before {
			background-image: url(../../icons/right-white.png);
			width: 20px;
			height: 20px;
			background-size: contain;
			display: inline-block;
			content: '';
		}
		& > li {
		  &[data-key="prev"] {
		    position: absolute;
		    left: 20px;
		    background-color: transparent !important;
		    top: -50vh;
		    i {
		    	font-size: 80px;
		    	text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		    }
		  }
		  &[data-key="next"] {
		    position: absolute;
		    right: 20px;
		    background-color: transparent !important;
		    top: -50vh;
		    i {
				font-size: 80px;
				text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		    }    
		  }
		}
	}
}