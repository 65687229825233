// Whatsapp

.whatsapp-button {
	display: flex;
	.container-icon {
		background-color: $publicWhatsapp;
		border: 2px solid $white;
		width: 50px;
		height: 50px;
		border-radius: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: relative;
		z-index: 10;
		img {
			width: 30px !important;
			height: 30px !important;
		}
	}
	.container-text {
		background-color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		display: inline-block;
		height: 40px;
		padding-left: 25px;
		padding-right: 15px;
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		border-radius: 10px;
		margin-left: -20px;
		position: relative;
		z-index: 5;
		margin-top: 5px;
		p {
			display: inline-block;
			text-shadow: none !important;
			color: $black;
			text-align: center;
			font-size: 14px;
			font-family: 'Roboto Bold';
			margin-top: 9px;
			padding-left: 15px;
			padding-right: 15px;
			@include ellipsis();
		}
	}
}

.whatsapp-global {
	position: fixed;
	bottom: 20px;
	right: 30px;
	z-index: 9999;
	@media (max-width: 600px) {
		text-align: center;
		right: auto;
		width: 100%;
		bottom: 15px;
		.whatsapp-button {
			display: inline-flex;
		}
	}
}