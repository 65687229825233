// Search Form

.search-form {
	.row-filter {
		button {
			background-color: $publicBlack;
			color: $white !important;
			width: 100%;
			border-radius: 30px;
		}
		input, select {
			border-radius: 30px !important;
			// background-color: $white !important;
			@media (max-width: 767px) {
				margin-bottom: 15px;
			}
		}
		margin-bottom: 0px;
	}
}