// Plugins
@import "../../node_modules/react-datepicker/src/stylesheets/datepicker";

// Archivo Principal

@import "scss/base/variables";
@import "scss/base/fonts";
@import "scss/base/mixins";
@import "scss/base/base";
@import "scss/base/menu";

// Components

@import "scss/components/button";
@import "scss/components/input";
@import "scss/components/card";
@import "scss/components/table";
@import "scss/components/title";
@import "scss/components/textarea";
@import "scss/components/card-title";
@import "scss/components/pagination";
@import "scss/components/modal";
@import "scss/components/select";
@import "scss/components/datepicker";
@import "scss/components/list";
@import "scss/components/tabs";
@import "scss/components/background";
@import "scss/components/form";
@import "scss/components/owl";
@import "scss/components/avatar";

// Screens

@import "scss/screens/login";
@import "scss/screens/profile";
@import "scss/screens/content";
@import "scss/screens/viewBrand";
@import "scss/screens/about-admin";
@import "scss/screens/contact-admin";
@import "scss/screens/vehicles";
@import "scss/screens/collections-admin";

// Public
#public {
  @import "scss/public/header";
  @import "scss/public/footer";
  @import "scss/public/home";
  @import "scss/public/inventory";
  @import "scss/public/search-form";
  @import "scss/public/whatsapp";
  @import "scss/public/vehicle-container";
  @import "scss/public/vehicle";
  @import "scss/public/banner";
  @import "scss/public/contact";
  @import "scss/public/pop-up";
  @import "scss/public/about-us";
  @import "scss/public/gallery";
}
