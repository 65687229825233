// Variables

$gray: #f2f2f2;
$blue: #dd3333;
$black: #222d32;
$black-dark: #000;
$white: #fff;
$input: #f1f1f1;
$active: #0d1214;
$danger: #e53935;
$green: #43a047;
$orange: #fb8c00;

$publicBlack: #222;
// $publicYellow: #f4ac00;
$publicYellow: #dd3333;
$publicBorderYellow: #f6bd33;
$publicGray: #dcdcdc;
$publicBlue: #dd3333;
$publicWhatsapp: #0cd028;
$publicBlueGray: #dd3333;
$publicGallery: #455286;
$publicGalleryTwo: #4E4F53;

$text-shadow: 0.1em 0.1em 0.2em #000000;

$font-family: "Roboto";
$font-family-bold: "Roboto Bold";
