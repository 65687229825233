.view-brand {
  // background-color: #f2f2f2;
  .infinite-scroll-component {
    overflow-x: hidden !important;
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: 767px) {
      padding: 0px;
      padding-right: 20px !important;
    }
    @include scrollHome();
  }

  .search-form {
    width: 95%;
    margin: 0 auto 18px auto;
  }

  .container-vehicles {
    padding-right: 15px !important;
    padding-left: 10px !important;
    .col-lg-4 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .container-loading {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .no-vehicles {
    text-transform: uppercase;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    font-family: "Roboto Bold";
  }

  .noUi-horizontal {
    height: 0.6rem;
    z-index: 0;
    .noUi-connect {
      background: #dd3333;
    }
    .noUi-handle {
      width: 1rem;
      height: 1rem;
      right: -12px;
      top: -5px;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      border-radius: 40px;
      outline: 0px !important;
      .noUi-touch-area {
        display: none;
      }
    }
    .noUi-handle::before,
    .noUi-handle::after {
      display: none;
    }
  }
  .black-text {
    color: hsl(0, 0%, 20%) !important;
    font-size: 0.75rem !important;
  }
  .banner-top {
    background-image: url("../../img/bg-view-brand.jpg");
    min-height: 341px;
    padding: 0.3rem;
    padding-bottom: 0.7rem;
    background-repeat: no-repeat;
    .row {
      min-height: 341px;
      align-items: center;
      .left-item {
        padding: 0 0 2.3rem 0;
        p {
          color: $white;
          padding: 15px 1.3rem;
        }
        h4 {
          color: #f4ac00;
          font-size: 1.75rem;
        }
      }
      .right-item {
        margin-top: 10%;
      }
    }
  }
  .body-contain {
    // padding: 0.5rem 1.1rem 0.5rem 4rem;
    // @media (max-width: 768px) {
    //   padding: 0.5rem 2rem 0.5rem 2rem;
    // }
    padding-left: 5px;
    .side-left {
      padding-right: 0;
      padding-left: 0;
      box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.24);
      height: 100%;
      margin-bottom: 20px;
      @media (min-width: 767px) {
        max-width: 22%;
      }
      .text-blue {
        color: #dd3333;
      }
      .section {
        padding: 0.4rem 0;
        &:nth-child(1) {
          padding-top: 0px;
        }
        .side-header {
          width: 100%;
          background-color: #f2f2f2;
          padding: 0.4rem 0.75rem;
          cursor: pointer;
          p {
            font-weight: bold;
            color: #000;
            margin: 0;
          }
        }
        .side-body {
          text-align: center;
          .basic-single {
            .select__control {
              background-color: transparent;
              border-color: transparent;
              .singleValue {
                max-width: max-content;
              }
            }
          }
          .tab:not(.full-width) {
            display: inline-block;
            width: auto;
            margin: 0 auto;
          }
          .tab {
            max-width: 92%;
            margin-right: 5px !important;
            margin-left: 5px !important;
            border: solid 1px #dd3333;
            border-radius: 40px;
            button {
              padding: 0px;
              margin-left: 5px;
              position: relative;
              box-shadow: none !important;
              outline: 0px !important;
              top: -1px;
              i.fa {
                color: darken($danger, 20);
              }
            }
            @media (min-width: 767px) {
              margin: 0.4rem 0.25rem;
            }
            p {
              color: #888c8c;
              font-size: 0.8rem;
              padding: 0.2rem 0.1rem;
              margin: 0;
              @include ellipsis();
            }
          }
          .model {
            p {
              margin: 0;
            }
          }
        }
      }
      background-color: $white;
      // position: fixed;
      overflow: hidden;
      z-index: 2;
      // max-height: 100vh;
      .btn-model {
        text-decoration: none;
        padding: 4px 0;
        p {
          color: #888c8c;
          font-size: 0.8rem;
        }
      }
      .btn-model-selected {
        text-shadow: 3px 4px 5px rgba(0, 0, 0, 0.27);
      }
    }
    .side-right {
      // max-height: 100vh;
      overflow-x: hidden;
      overflow-y: hidden;
      @media (min-width: 767px) {
        max-width: 78%;
        flex: 0 0 78%;
      }
      @media (max-width: 767px) {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0px !important;
      }
      .scroll-items {
        // background-color: right: ;d;
        // height: 94vh;
        overflow: hidden;
        .card {
          border-radius: 0;
        }
        .details-container {
          text-align: center;
          margin: 0;
          .price-container {
            background-color: $publicYellow;
            color: $publicBlack;
            padding: 0;
          }
          .card-title {
            margin-bottom: 0 !important;
            padding: 0.5rem 0;
          }
        }
        .bottom-container {
          padding: 0.4rem 0;
          .info {
            padding: 0.4rem 0.6rem;
            p.p-bottom {
              font-size: 0.65rem;
            }
            .yellow-icon {
              color: $publicYellow;
            }
          }
          button.view-details {
            width: 100%;
            border: solid 1px #f6bd33 !important;
            background-color: rgba(23, 39, 104, 0.85);
          }
        }
      }
    }
  }
  .container-margin-top {
    padding-top: 10px;
  }
}
